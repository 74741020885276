<template>
  <section class="n-section-primary tw-bg-cover tw-bg-center" :style="`background-image: url(${vars.backgroundImage})`">
    <div class="tw-absolute tw-inset-0 tw-opacity-80" :style="`background-color: var(--c-secondary);`"></div>
    <div class="tw-z-10 tw-flex tw-flex-col tw-items-center tw-text-center">
      <div class="tw-flex tw-flex-wrap tw-justify-center tw-text-center lg:tw-gap-4" v-html="vars.titleHtml"></div>
      <div v-if="descriptions" class="tw-space-y-1">
        <p
          v-for="({ description }, index) in descriptions"
          :key="`takeaction-description-${index}`"
          class="tw-max-w-2xl tw-text-white"
        >
          {{ description }}
        </p>
      </div>
      <div v-if="buttons" class="tw-m-6 tw-flex tw-flex-wrap tw-justify-center tw-gap-2">
        <nuxt-link
          v-for="(button, index) in buttons"
          :key="`takeaction-button-${index}`"
          :to="button.link"
          :class="`n-${button.type}`"
          >{{ button.label }}</nuxt-link
        >
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'TakeAction',

  mixins: [ComponentMixin],

  computed: {
    descriptions() {
      return this.groupedVariables.descriptions;
    },
    buttons() {
      return this.groupedVariables.buttons;
    },
  },
});
</script>
