<template>
  <section class="n-section-primary" :style="`background-color: var(--c-${vars.backgroundColor})`">
    <div class="tw-flex tw-w-full tw-max-w-sm tw-flex-col tw-items-center tw-text-black md:tw-max-w-none">
      <div
        v-html="vars.titleHtml"
        class="tw-mb-3 tw-flex tw-w-full tw-flex-row tw-flex-wrap tw-gap-x-4 tw-text-start tw-text-black md:tw-mb-6"
      ></div>
      <div class="tw-flex tw-w-full tw-flex-row tw-items-center tw-justify-center">
        <div
          data-w-id="080a9ca2-7a2d-2ca3-3b22-f42b27569daf"
          class="nav-left-arrow w-nav-arrow-left tw-hidden md:tw-flex"
          :isDisabled="!canPaginateBack"
          @click="() => swiper.slidePrev()"
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.011 3.49956C16.1418 3.6335 16.2151 3.81332 16.2151 4.00056C16.2151 4.18781 16.1418 4.36762 16.011 4.50156L8.70149 12.0001L16.011 19.4971C16.1418 19.631 16.2151 19.8108 16.2151 19.9981C16.2151 20.1853 16.1418 20.3651 16.011 20.4991C15.9474 20.5644 15.8714 20.6163 15.7874 20.6517C15.7034 20.6872 15.6132 20.7054 15.522 20.7054C15.4308 20.7054 15.3406 20.6872 15.2566 20.6517C15.1726 20.6163 15.0966 20.5644 15.033 20.4991L7.25999 12.5236C7.12347 12.3835 7.04706 12.1957 7.04706 12.0001C7.04706 11.8045 7.12347 11.6166 7.25999 11.4766L15.033 3.50106C15.0966 3.43575 15.1726 3.38383 15.2566 3.34839C15.3406 3.31294 15.4308 3.29468 15.522 3.29468C15.6132 3.29468 15.7034 3.31294 15.7874 3.34839C15.8714 3.38383 15.9474 3.43575 16.011 3.50106V3.49956Z"
              fill="#000"
            />
          </svg>
        </div>
        <div class="tw-w-full">
          <Swiper
            effect="fade"
            :clickable="true"
            :modules="[SwiperGrid]"
            @slideChange="onSlideChange"
            :watch-overflow="true"
            :breakpoints="{
              320: {
                slidesPerView: 2,
                spaceBetween: 30,
                grid: { rows: 2, fill: 'row' },
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 6,
                spaceBetween: 10,
              },
            }"
            :spaceBetween="30"
            @swiper="onSwiper"
            class="tw-max-w-sm md:tw-max-w-none"
          >
            <SwiperSlide
              v-for="(post, index) in posts"
              :key="`socialgallery-post-${index}`"
              @click="toggleSocialPopup(post, true)"
              class="tw-group tw-h-full tw-w-full hover:tw-cursor-pointer"
            >
              <LibraryImage :src="post.photo" :h-ratio="1.5" :w-ratio="0.8" />
              <div
                class="tw-absolute tw-inset-0 tw-flex tw-flex-col-reverse tw-items-center tw-bg-[--c-primary] tw-text-white tw-opacity-0 tw-duration-500 group-hover:tw-opacity-80"
              ></div>
              <div
                class="tw-absolute tw-inset-0 tw-flex tw-cursor-pointer tw-flex-row tw-items-start tw-justify-end tw-p-3 tw-text-white tw-opacity-0 tw-duration-500 group-hover:tw-opacity-100 lg:tw-p-6"
              >
                <svg viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="tw-w-6 lg:tw-w-8">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M21.375 21.375H4.125C3.71016 21.375 3.375 21.0398 3.375 20.625V3.375C3.375 2.96016 3.71016 2.625 4.125 2.625H12.5625C12.6656 2.625 12.75 2.70937 12.75 2.8125V4.125C12.75 4.22812 12.6656 4.3125 12.5625 4.3125H5.0625V19.6875H20.4375V12.1875C20.4375 12.0844 20.5219 12 20.625 12H21.9375C22.0406 12 22.125 12.0844 22.125 12.1875V20.625C22.125 21.0398 21.7898 21.375 21.375 21.375ZM18.8173 4.66713L17.5938 3.4437C17.4837 3.33354 17.5493 3.1437 17.704 3.12495L21.9087 2.63276C22.0282 2.6187 22.1313 2.71948 22.1173 2.84135L21.6251 7.04604C21.6063 7.20073 21.4165 7.26635 21.3063 7.1562L20.0782 5.92807L14.0735 11.9328C14.0009 12.0054 13.8813 12.0054 13.8087 11.9328L12.8149 10.939C12.7423 10.8664 12.7423 10.7468 12.8149 10.6742L18.8173 4.66713Z"
                    fill="white"
                  />
                </svg>
              </div>
            </SwiperSlide>
            <div class="controls !tw-mt-8 md:!tw-hidden">
              <div
                data-w-id="080a9ca2-7a2d-2ca3-3b22-f42b27569daf"
                class="slide-left-arrow w-slider-arrow-left"
                :isDisabled="!canPaginateBack"
                @click="() => swiper.slidePrev()"
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M16.011 3.49956C16.1418 3.6335 16.2151 3.81332 16.2151 4.00056C16.2151 4.18781 16.1418 4.36762 16.011 4.50156L8.70149 12.0001L16.011 19.4971C16.1418 19.631 16.2151 19.8108 16.2151 19.9981C16.2151 20.1853 16.1418 20.3651 16.011 20.4991C15.9474 20.5644 15.8714 20.6163 15.7874 20.6517C15.7034 20.6872 15.6132 20.7054 15.522 20.7054C15.4308 20.7054 15.3406 20.6872 15.2566 20.6517C15.1726 20.6163 15.0966 20.5644 15.033 20.4991L7.25999 12.5236C7.12347 12.3835 7.04706 12.1957 7.04706 12.0001C7.04706 11.8045 7.12347 11.6166 7.25999 11.4766L15.033 3.50106C15.0966 3.43575 15.1726 3.38383 15.2566 3.34839C15.3406 3.31294 15.4308 3.29468 15.522 3.29468C15.6132 3.29468 15.7034 3.31294 15.7874 3.34839C15.8714 3.38383 15.9474 3.43575 16.011 3.50106V3.49956Z"
                    fill="#6EADAB"
                  />
                </svg>
                <div class="slide-circle slider-circle-transform"></div>
              </div>
              <div
                data-w-id="080a9ca2-7a2d-2ca3-3b22-f42b27569db1"
                class="slide-right-arrow w-slider-arrow-right"
                :isDisabled="!canPaginateNext"
                @click="() => swiper.slideNext()"
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.98901 3.49956C7.85817 3.6335 7.78491 3.81332 7.78491 4.00056C7.78491 4.18781 7.85817 4.36762 7.98901 4.50156L15.2985 12.0001L7.98901 19.4971C7.85817 19.631 7.78491 19.8108 7.78491 19.9981C7.78491 20.1853 7.85817 20.3651 7.98901 20.4991C8.0526 20.5644 8.12864 20.6163 8.21263 20.6517C8.29661 20.6872 8.38685 20.7054 8.47801 20.7054C8.56917 20.7054 8.65941 20.6872 8.74339 20.6517C8.82738 20.6163 8.90342 20.5644 8.96701 20.4991L16.74 12.5236C16.8765 12.3835 16.9529 12.1957 16.9529 12.0001C16.9529 11.8045 16.8765 11.6166 16.74 11.4766L8.96701 3.50106C8.90342 3.43575 8.82738 3.38383 8.74339 3.34839C8.65941 3.31294 8.56917 3.29468 8.47801 3.29468C8.38685 3.29468 8.29661 3.31294 8.21263 3.34839C8.12864 3.38383 8.0526 3.43575 7.98901 3.50106V3.49956Z"
                    fill="#6EADAB"
                  />
                </svg>
                <div class="slide-circle right"></div>
              </div>
            </div>
          </Swiper>
        </div>
        <div
          data-w-id="080a9ca2-7a2d-2ca3-3b22-f42b27569daf"
          class="nav-right-arrow w-nav-arrow-right tw-hidden md:tw-flex"
          :isDisabled="!canPaginateNext"
          @click="() => swiper.slideNext()"
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.98901 3.49956C7.85817 3.6335 7.78491 3.81332 7.78491 4.00056C7.78491 4.18781 7.85817 4.36762 7.98901 4.50156L15.2985 12.0001L7.98901 19.4971C7.85817 19.631 7.78491 19.8108 7.78491 19.9981C7.78491 20.1853 7.85817 20.3651 7.98901 20.4991C8.0526 20.5644 8.12864 20.6163 8.21263 20.6517C8.29661 20.6872 8.38685 20.7054 8.47801 20.7054C8.56917 20.7054 8.65941 20.6872 8.74339 20.6517C8.82738 20.6163 8.90342 20.5644 8.96701 20.4991L16.74 12.5236C16.8765 12.3835 16.9529 12.1957 16.9529 12.0001C16.9529 11.8045 16.8765 11.6166 16.74 11.4766L8.96701 3.50106C8.90342 3.43575 8.82738 3.38383 8.74339 3.34839C8.65941 3.31294 8.56917 3.29468 8.47801 3.29468C8.38685 3.29468 8.29661 3.31294 8.21263 3.34839C8.12864 3.38383 8.0526 3.43575 7.98901 3.50106V3.49956Z"
              fill="#000"
            />
          </svg>
        </div>
      </div>
      <popup v-if="selectedPost && popupConfig.isActive" :config="popupConfig" custom-width="65%" class="!tw-z-50">
        <template #content>
          <div
            class="tw-grid tw-h-full tw-w-full tw-grid-cols-12 tw-gap-y-4 tw-py-4 tw-text-black lg:tw-gap-x-12 xl:tw-min-h-[500px]"
          >
            <div
              class="tw-col-span-12 tw-flex tw-items-center tw-justify-center md:tw-col-span-8 md:tw-col-start-3 lg:tw-col-span-5"
            >
              <div class="tw-h-full tw-w-full">
                <div style="position: relative; height: 100%; width: 100%">
                  <iframe
                    :src="selectedPost.video"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                    class="tw-h-full tw-max-h-[570px] tw-min-h-[300px] tw-w-full tw-min-w-[200px]"
                  ></iframe>
                </div>
              </div>
            </div>
            <div
              class="tw-col-span-12 tw-flex tw-w-full tw-flex-col tw-justify-center tw-gap-2 lg:tw-col-span-7 xl:tw-gap-5"
            >
              <h3 class="tw-line-clamp-4">{{ selectedPost.popup_title }}</h3>
              <div
                v-html="selectedPost.popup_description"
                class="tw-flex tw-max-h-[250px] tw-flex-col tw-gap-2 tw-overflow-auto lg:tw-max-h-[400px]"
              ></div>
            </div>
          </div>
        </template>
      </popup>
    </div>
  </section>
</template>
<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import Popup from '~/components/common/popup/Popup.vue';
import 'swiper/css';

export default defineNuxtComponent({
  name: 'SocialGallery',

  components: { Popup },

  mixins: [ComponentMixin],

  data() {
    return {
      swiper: null,
      canPaginateBack: true,
      canPaginateNext: true,
      selectedPost: null,
      popupConfig: {
        isActive: false,
      },
    };
  },
  computed: {
    posts() {
      return this.groupedVariables.posts;
    },
  },
  methods: {
    toggleSocialPopup(post = null, value = false) {
      if (post.popup_title) {
        this.selectedPost = post;
        this.popupConfig.isActive = value;
      }
    },
    onSwiper(swiper) {
      this.swiper = swiper;
    },

    onSlideChange() {
      const isAtStart = this.swiper.isBeginning;
      const isAtEnd = this.swiper.isEnd;
      if (isAtStart) this.canPaginateBack = false;
      else this.canPaginateBack = true;
      if (isAtEnd) this.canPaginateNext = false;
      else this.canPaginateNext = true;
    },
  },

  mounted() {
    this.onSlideChange();
  },
});
</script>

<style scoped>
::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: var(--c-primary);
}
::-webkit-scrollbar-thumb:window-inactive {
  display: none;
}
</style>
