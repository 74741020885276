<template>
  <section class="n-section-primary" :style="`background-color: var(--c-${vars.backgroundColor})`">
    <div class="tw-w-full">
      <div class="tw-mb-5 tw-text-left">
        <h2 class="tw-font-semibold" style="color: var(--c-text_black)">
          {{ vars.titleText }}
        </h2>
      </div>
      <div class="tw-space-y-10">
        <div
          v-for="(branch, index) in branches"
          :key="`contact-us-branch-${index}`"
          class="tw-grid tw-grid-cols-1 tw-overflow-hidden xl:tw-grid-cols-2"
          :style="`border-radius: var(--rounded)`"
        >
          <div>
            <LibraryImage :src="branch.photo" :w-ratio="1414" :h-ratio="1034" class="tw-h-full" />
          </div>
          <div
            class="tw-space-y-10 tw-p-10 lg:tw-p-12"
            :style="`
              color: var(--c-text_black);
              background-color: var(--c-${vars.cardBackgroundColor});
              `"
          >
            <header>
              <h3>{{ branch.title }}</h3>
            </header>
            <div class="tw-grid tw-grid-cols-1 tw-gap-5 lg:tw-grid-cols-2">
              <div class="tw-col-span-1 tw-space-y-8">
                <div class="tw-flex tw-items-start tw-gap-3">
                  <div class="tw-min-w-9">
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M18 17.25C17.0054 17.25 16.0516 16.8549 15.3483 16.1517C14.6451 15.4484 14.25 14.4946 14.25 13.5C14.25 12.5054 14.6451 11.5516 15.3483 10.8483C16.0516 10.1451 17.0054 9.75 18 9.75C18.9946 9.75 19.9484 10.1451 20.6517 10.8483C21.3549 11.5516 21.75 12.5054 21.75 13.5C21.75 13.9925 21.653 14.4801 21.4645 14.9351C21.2761 15.39 20.9999 15.8034 20.6517 16.1517C20.3034 16.4999 19.89 16.7761 19.4351 16.9645C18.9801 17.153 18.4925 17.25 18 17.25ZM18 3C15.2152 3 12.5445 4.10625 10.5754 6.07538C8.60625 8.04451 7.5 10.7152 7.5 13.5C7.5 21.375 18 33 18 33C18 33 28.5 21.375 28.5 13.5C28.5 10.7152 27.3938 8.04451 25.4246 6.07538C23.4555 4.10625 20.7848 3 18 3Z"
                        fill="var(--c-primary)"
                      />
                    </svg>
                  </div>
                  <div class="tw-w-full tw-space-y-2">
                    <h5>ADDRESS</h5>
                    <p>
                      {{ branch.address }}
                    </p>
                  </div>
                </div>
                <div class="tw-flex tw-items-start tw-gap-3">
                  <div class="tw-min-w-9">
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M9.93 16.185C12.09 20.43 15.57 23.895 19.815 26.07L23.115 22.77C23.52 22.365 24.12 22.23 24.645 22.41C26.325 22.965 28.14 23.265 30 23.265C30.825 23.265 31.5 23.94 31.5 24.765V30C31.5 30.825 30.825 31.5 30 31.5C15.915 31.5 4.5 20.085 4.5 6C4.5 5.175 5.175 4.5 6 4.5H11.25C12.075 4.5 12.75 5.175 12.75 6C12.75 7.875 13.05 9.675 13.605 11.355C13.77 11.88 13.65 12.465 13.23 12.885L9.93 16.185Z"
                        fill="var(--c-primary)"
                      />
                    </svg>
                  </div>
                  <div class="tw-w-full tw-space-y-2">
                    <h5>PHONE</h5>
                    <p>
                      {{ branch.primary_phone }}
                    </p>
                    <p v-if="branch.secondary_phone">
                      {{ branch.secondary_phone }}
                    </p>
                  </div>
                </div>
                <div class="tw-flex tw-items-start tw-gap-3">
                  <div class="tw-min-w-9">
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6.924 28.5C6.233 28.5 5.6565 28.269 5.1945 27.807C4.7325 27.345 4.501 26.768 4.5 26.076V9.924C4.5 9.233 4.7315 8.6565 5.1945 8.1945C5.6575 7.7325 6.2335 7.501 6.9225 7.5H29.0775C29.7675 7.5 30.3435 7.7315 30.8055 8.1945C31.2675 8.6575 31.499 9.234 31.5 9.924V26.0775C31.5 26.7675 31.2685 27.344 30.8055 27.807C30.3425 28.27 29.7665 28.501 29.0775 28.5H6.924ZM18 18.174L30 10.3275L29.538 9L18 16.5L6.462 9L6 10.3275L18 18.174Z"
                        fill="var(--c-primary)"
                      />
                    </svg>
                  </div>
                  <div class="tw-w-full tw-space-y-2 tw-break-words">
                    <h5>EMAIL</h5>
                    <p>
                      {{ branch.primary_email }}
                    </p>
                    <p v-if="branch.secondary_email">
                      {{ branch.secondary_email }}
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div class="tw-flex tw-items-start tw-gap-3">
                  <div class="tw-w-9">
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M25.5 5.00984C27.7803 6.32637 29.6739 8.21995 30.9904 10.5003C32.3069 12.7806 33 15.3672 33 18.0003C33 20.6334 32.3069 23.2201 30.9903 25.5003C29.6738 27.7806 27.7802 29.6742 25.4998 30.9907C23.2195 32.3072 20.6328 33.0003 17.9998 33.0002C15.3667 33.0002 12.78 32.307 10.4998 30.9905C8.21947 29.6739 6.32593 27.7803 5.00945 25.4999C3.69297 23.2196 2.99993 20.6329 3 17.9998L3.0075 17.5138C3.0915 14.9233 3.84532 12.3988 5.19546 10.1863C6.5456 7.97388 8.44598 6.14902 10.7113 4.88966C12.9767 3.63031 15.5297 2.97943 18.1215 3.0005C20.7133 3.02156 23.2554 3.71384 25.5 5.00984ZM18 8.99984C17.6022 8.99984 17.2206 9.15787 16.9393 9.43918C16.658 9.72048 16.5 10.102 16.5 10.4998V18.0388L16.5135 18.1963L16.5435 18.3568L16.6035 18.5503L16.6755 18.7033L16.7445 18.8203L16.8075 18.9103L16.911 19.0303L17.043 19.1548L17.1675 19.2478L21.6675 22.2478C21.8314 22.3572 22.0152 22.4331 22.2085 22.4714C22.4017 22.5097 22.6006 22.5096 22.7938 22.471C22.987 22.4324 23.1707 22.3561 23.3344 22.2466C23.4981 22.137 23.6387 21.9962 23.748 21.8323C23.8573 21.6684 23.9333 21.4846 23.9716 21.2914C24.0099 21.0981 24.0097 20.8992 23.9711 20.706C23.9326 20.5128 23.8563 20.3291 23.7467 20.1654C23.6371 20.0017 23.4964 19.8612 23.3325 19.7518L19.5 17.1958V10.4998C19.5 10.1324 19.3651 9.77783 19.1209 9.50328C18.8768 9.22873 18.5404 9.05332 18.1755 9.01034L18 8.99984Z"
                        fill="var(--c-primary)"
                      />
                    </svg>
                  </div>
                  <div class="tw-w-full">
                    <h5>OPENING HOURS</h5>
                    <div>
                      <div v-if="branch.general_office_hour" class="tw-flex tw-justify-between">
                        <p>General:</p>
                        {{ branch.general_office_hour }}
                      </div>
                      <div v-else class="tw-space-y-4">
                        <div class="tw-flex tw-justify-between">
                          <p>Monday:</p>
                          <p>{{ branch.monday }}</p>
                        </div>
                        <div class="tw-flex tw-justify-between">
                          <p>Tuesday:</p>
                          <p>{{ branch.tuesday }}</p>
                        </div>
                        <div class="tw-flex tw-justify-between">
                          <p>Wednesday:</p>
                          <p>{{ branch.wednesday }}</p>
                        </div>
                        <div class="tw-flex tw-justify-between">
                          <p>Thursday:</p>
                          <p>{{ branch.thursday }}</p>
                        </div>
                        <div class="tw-flex tw-justify-between">
                          <p>Friday:</p>
                          <p>{{ branch.friday }}</p>
                        </div>
                        <div class="tw-flex tw-justify-between">
                          <p>Saturday:</p>
                          <p>{{ branch.saturday }}</p>
                        </div>
                        <div class="tw-flex tw-justify-between">
                          <p>Sunday:</p>
                          <p>{{ branch.sunday }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="() => isExistsSocialLink(branch)" class="tw-flex tw-gap-3">
              <a v-if="branch.facebook_url" :href="branch.facebook_url" class="social_icons" target="_blank">
                <img :src="vars.facebook_icon" />
              </a>
              <a v-if="branch.twitter_url" :href="branch.twitter_url" class="social_icons" target="_blank">
                <img :src="vars.twitter_icon" />
              </a>
              <a v-if="branch.linkedin_url" :href="branch.linkedin_url" class="social_icons" target="_blank">
                <img :src="vars.linkedin_icon" />
              </a>
              <a v-if="branch.youtube_url" :href="branch.youtube_url" class="social_icons" target="_blank">
                <img :src="vars.youtube_icon" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'ContactUs',

  mixins: [ComponentMixin],

  computed: {
    branches() {
      return this.groupedVariables.branches;
    },
  },
});
</script>
<style scoped>
.social_icons {
  background-color: var(--c-primary);
  border-radius: 100% !important;
  padding: 10px !important;
}
</style>
