<template>
  <nav
    :class="[
      'tw-flex tw-items-center tw-justify-between tw-whitespace-nowrap tw-p-6 tw-text-center tw-duration-300 xl:tw-justify-around',
      'tw-fixed tw-left-0 tw-top-0 tw-z-50 tw-w-full tw-border-b',
    ]"
    :style="`
      ${
        isScrolled || vars.fixedColorBool || menuVisible
          ? `background-color: var(--c-${vars.backgroundColor});`
          : 'background-color: transparent;'
      }
      color: var(--c-${vars.textColor});
  `"
  >
    <div class="tw-max-w-48 tw-justify-center xl:tw-max-w-60">
      <nuxt-link style="all: unset !important" to="/" :external="true">
        <NuxtImg :src="company.logo" :alt="company.name" class="tw-cursor-pointer" />
      </nuxt-link>
    </div>
    <div class="tw-z-40 tw-flex tw-justify-start tw-pt-2 xl:tw-hidden">
      <button id="menu-button" class="focus:outline-none tw-flex" @click="toggleMenu">
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.5 27V24H31.5V27H4.5ZM4.5 19.5V16.5H31.5V19.5H4.5ZM4.5 12V9H31.5V12H4.5Z" fill="white" />
        </svg>
      </button>
    </div>
    <div class="tw-hidden xl:tw-block">
      <div class="tw-ml-4 tw-hidden xl:tw-block">
        <ul class="tw-flex tw-items-center tw-gap-6 tw-align-middle lg:tw-shrink">
          <li
            v-for="(menuGroup, groupIndex) in allMenu[0]"
            :key="`menuGroupIndex-${groupIndex}`"
            class="tw-group tw-relative tw-py-5"
          >
            <div class="tw-flex tw-items-center tw-justify-center">
              <nuxt-link
                :external="true"
                :to="menuGroup.url"
                :target="menuGroup.is_open_new_tab ? '_blank' : ''"
                class="n-link"
              >
                {{ menuGroup.name }}
              </nuxt-link>
              <span v-if="menuGroup.has_sub_menu" class="nc-icon-chevron-down sub-menu-arrow tw-ml-2"></span>
            </div>
            <ul
              v-if="menuGroup.sub_menus && menuGroup.sub_menus.length && menuGroup.has_sub_menu"
              class="tw-absolute tw-mt-3 tw-hidden tw-rounded-sm tw-px-6 tw-py-4 tw-text-left tw-text-white tw-shadow-md group-hover:!tw-block"
              :style="`background-color: var(--c-primary); border-radius: var(--rounded-sm);`"
            >
              <li
                v-for="(subMenu, subMenuIndex) in menuGroup.sub_menus"
                :key="`${subMenuIndex}-subMenu-${groupIndex}`"
                class="tw-w-[220px] tw-py-4"
                style="border-color: var(--c-border)"
                :class="{
                  'tw-border-b': subMenuIndex !== menuGroup.sub_menus.length - 1,
                }"
              >
                <nuxt-link
                  class="n-link tw-block tw-px-4 tw-py-2 hover:tw-ml-4"
                  :external="true"
                  :to="subMenu.url"
                  :target="subMenu.is_open_new_tab ? '_blank' : ''"
                >
                  {{ subMenu.name }}
                </nuxt-link>
              </li>
            </ul>
          </li>
          <li>
            <nuxt-link to="/contact-us" class="n-outlined" :external="true">GET IN TOUCH</nuxt-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div
    class="tw-fixed -tw-top-[2000px] tw-left-0 tw-z-30 tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-overflow-y-auto tw-duration-300"
    :class="menuVisible === true ? '!tw-top-0' : '-tw-top-[1000px]'"
    :style="`background-color: var(--c-${vars.backgroundColor}); color: var(--c-${vars.textColor});`"
  >
    <ul class="tw-mt-40 tw-flex tw-flex-col tw-items-center tw-pb-10">
      <li
        v-for="(menuGroup, groupIndex) in allMenu[0]"
        :key="`menuGroupIndex-${groupIndex}`"
        class="tw-group tw-relative tw-py-5"
      >
        <div class="tw-flex tw-items-center tw-justify-center">
          <nuxt-link
            :external="true"
            :to="menuGroup.url"
            :target="menuGroup.is_open_new_tab ? '_blank' : ''"
            class="n-link"
          >
            {{ menuGroup.name }}
          </nuxt-link>
          <span
            v-if="menuGroup.has_sub_menu"
            class="nc-icon-chevron-down sub-menu-arrow theme3-menu-link tw-ml-2"
          ></span>
        </div>
        <ul
          v-if="menuGroup.sub_menus && menuGroup.sub_menus.length && menuGroup.has_sub_menu"
          class="tw-absolute tw-z-50 tw-mt-2 tw-hidden tw-rounded-sm tw-px-6 tw-py-4 tw-text-left tw-text-white tw-shadow-md group-hover:!tw-block"
          :style="`background-color: var(--c-primary); border-radius: var(--rounded-sm);`"
        >
          <li
            v-for="(subMenu, subMenuIndex) in menuGroup.sub_menus"
            :key="`${subMenuIndex}-subMenu-${groupIndex}`"
            class="tw-w-[150px] tw-py-4 md:tw-w-[220px]"
            style="border-color: var(--c-border)"
            :class="{
              'tw-border-b': subMenuIndex !== menuGroup.sub_menus.length - 1,
            }"
          >
            <nuxt-link
              class="n-link tw-block tw-px-4 tw-py-2 hover:tw-ml-4"
              :external="true"
              :to="subMenu.url"
              :target="subMenu.is_open_new_tab ? '_blank' : ''"
            >
              {{ subMenu.name }}
            </nuxt-link>
          </li>
        </ul>
      </li>
      <li class="tw-pt-5">
        <nuxt-link to="/contact-us" class="n-outlined">GET IN TOUCH</nuxt-link>
      </li>
    </ul>
  </div>
</template>
<script lang="ts">
import { mapState } from 'pinia';
import { MenuTypes, ViewTypes } from '~/constants/ui.constants';
import { ComponentMixin } from '~/mixins/component.mixin';
import { useWebsiteStore } from '~/units/website/store';
import { useCoreStore } from '~/units/core/store';

type Menu = {};

export default defineNuxtComponent({
  name: 'Theme5BaseHeaderOne',

  mixins: [ComponentMixin],

  props: {
    component: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      ViewTypes,
      menuVisible: false,
      isScrolled: false,
      openSubMenu: null,
    };
  },

  computed: {
    ...mapState(useWebsiteStore, ['headerMenuItems']),
    ...mapState(useCoreStore, ['isAnythingOnTopVisible']),

    headerMenu() {
      return this.headerMenuItems as Menu[];
    },

    routeUrl() {
      const route = useRoute();
      return route.path;
    },

    allMenu() {
      const period = 9;
      const columnsCount = Math.ceil(this.columnOneMenu.length / period);
      const groups = [];

      for (let i = 0; i < columnsCount; i++) {
        groups[i] = [];
        for (let p = 0; p < period; p++) {
          const index = period * i + p;
          if (this.columnOneMenu[index]) {
            groups[i].push(this.columnOneMenu[index]);
          }
        }
      }

      return groups;
    },

    columnOneMenu() {
      const content = this.component.variables['column_one_content-text'];
      if (content === MenuTypes.HEADER_MENU) {
        return this.headerMenu;
      }
      return [];
    },
  },

  methods: {
    toggleSubMenu(index) {
      this.openSubMenu = this.openSubMenu === index ? null : index;
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
    handleScroll() {
      this.isScrolled = window.scrollY > 0;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
});
</script>
