<template>
  <section
    class="n-section-primary tw-min-h-[60vh] tw-space-y-5 tw-bg-cover tw-bg-center"
    :style="`background-image: url(${vars.backgroundImage})`"
  >
    <!-- variant 1 start-->
    <div v-if="variant === variants.v1">
      <div class="tw-absolute tw-inset-0 tw-bg-black tw-opacity-30"></div>
      <div class="tw-relative tw-z-10 tw-text-center">
        <h1>{{ vars.titleText }}</h1>
      </div>
    </div>
    <!-- variant 1 end-->

    <!-- variant 2 start-->
    <div v-else-if="variant === variants.v2">
      <div class="tw-absolute tw-inset-0 tw-bg-black tw-opacity-30"></div>
      <div class="tw-relative tw-z-10 tw-text-center">
        <h2 style="color: var(--c-text-secondary)">{{ vars.titleText }}</h2>
        <div class="tw-flex tw-flex-col tw-items-center tw-justify-center">
          <div
            v-for="(description, index) in descriptions"
            :key="`introsection-description-${index}`"
            class="tw-max-w-3xl"
            style="color: var(--c-text-secondary)"
          >
            {{ description.description }}
          </div>
        </div>
        <div class="tw-mt-10 tw-flex tw-flex-wrap tw-justify-center tw-gap-2">
          <nuxt-link
            v-for="(button, index) in buttons"
            :key="`introsection-button-${index}`"
            :to="button.button_link"
            :class="`n-${button.button_type}`"
            >{{ button.button_label }}
          </nuxt-link>
        </div>
      </div>
    </div>
    <!-- variant 2 end-->
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'IntroSection',

  mixins: [ComponentMixin],

  data() {
    return {
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },
  computed: {
    variant() {
      return useVariant(this.component);
    },

    descriptions() {
      return this.groupedVariables.descriptions;
    },

    buttons() {
      return this.groupedVariables.buttons;
    },
  },
});
</script>

<style scoped>
h1 {
  color: var(--c-text_white);
}
</style>
